import request from "@/utils/request";

// 获取用户信息
export function getUserInfor(data) {
  return request({
    url: "/api/getUserInfor",
    method: "POST",
    data,
  });
}

// 获取首页人力资源信息数量
export function getUserMsgTotal(data) {
  return request({
    url: "/api/getUserMsgTotal",
    method: "POST",
    data,
  });
}

// 个人中心
// 获取首页个人中心功能区
export function getUserFunction(data) {
  return request({
    url: "/api/getUserFunction",
    method: "POST",
    data,
  });
}

// 常见功能
// 获取首页常见功能
export function getUserNormalFunction(data) {
  return request({
    url: "/api/getUserNormalFunction",
    method: "POST",
    data,
  });
}

/* 待办 */
// 获取首页请假审批
export function getUserLeaveApproval(data) {
  return request({
    url: "/api/getUserLeaveApproval",
    method: "POST",
    data,
  });
}

// 获取首页专家库待办
export function getUserExpertLibrary(data) {
  return request({
    url: "/api/getUserExpertLibrary",
    method: "POST",
    data,
  });
}

// 获取首页考察鉴定
export function getUserInvestigation(data) {
  return request({
    url: "/api/getUserInvestigation",
    method: "POST",
    data,
  });
}

// 处理待办
export function dealBacklog(thingType, id) {
  return request({
    url: "/api/dealBacklog",
    method: "POST",
    data: {
      thingType,
      id,
    },
  });
}

// 已办未结

// 人力园地
// 获取首页热点问题
export function getUserHot(data) {
  return request({
    url: "/api/getUserHot",
    method: "POST",
    data,
  });
}

// 获取首页操作手册
export function getOperationManual(data) {
  return request({
    url: "/api/getOperationManual",
    method: "POST",
    data,
  });
}

// 常用模版下载
// 获取常用模版
export function getCommonlyTemplate(data) {
  return request({
    url: "/api/getCommonlyTemplate",
    method: "POST",
    responseType: "blob",
    data,
  });
}

// 获取个人报表
export function getPersonalStatements(data) {
  return request({
    url: "/api/getPersonalStatements",
    method: "POST",
    data,
  });
}

// 获取管理员报表
export function getAdminStatements(data) {
  return request({
    url: "/api/getAdminStatements",
    method: "POST",
    data,
  });
}
