<template>
  <div id="app">
    <navbar></navbar>
    <router-view />
    <!-- "core-js": "^3.8.3", -->
  </div>
</template>
<script>
import navbar from '@/components/nav/navbar.vue';
export default {
  components: {
    navbar,
  },
};
</script>
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
