<template>
  <div>
    <div class="navbar">
      <div class="nav-left ml-20 flex flex-x-top flex-y-center">
        <img src="../../../public/static/logo.png" alt="" srcset="" class="img-logo" />
        <span class="logo-text ml-10 fw">上海移动人力资源门户</span>
      </div>
      <div class="nav-right flex flex-x-top flex-y-center">
        <span class="username fw">
          您好，
          <span>{{ username }}</span>
        </span>
        <div class="ml-20 logout fw">
          <i
            class="el-icon-setting"
            style="color: #10759f; vertical-align: middle; font-size: 18px"
          ></i>
          <span class="ml-5" @click="showLogout">退出</span>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showLogoutDialog" width="30%" title="提示">
      <span>确定要退出登录吗？</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showLogoutDialog = false">取消</el-button>
          <el-button type="primary" @click="logout">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <div class="navTab">
      <ul class="flex fw just-around flex-y-center">
        <li>
          <router-link to="/home">首页</router-link>
        </li>
        <li>
          <router-link to="/home">请销假({{ leaveMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">人才库({{ personMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">内部招聘({{ internalAdvertiseMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">外部招聘({{ externalAdvertiseMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">中移招聘({{ zgydAdvertiseMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">人事核心({{ oaMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">试用期({{ probationeMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">培训({{ cultivateMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">绩效上报({{ performanceMsg }})</router-link>
        </li>
        <li>
          <router-link to="/home">
            <el-icon style="color: #fff; vertical-align: middle; font-size: 25px">
              <Setting />
            </el-icon>
            管理员
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getUserMsgTotal } from '@/api/home.js';
export default {
  data() {
    return {
      username: '管理员',
      showLogoutDialog: false,
      leaveMsg: 0,
      personMsg: 0,
      internalAdvertiseMsg: 1,
      externalAdvertiseMsg: 0,
      zgydAdvertiseMsg: 0,
      oaMsg: 1,
      probationeMsg: 3,
      cultivateMsg: 0,
      performanceMsg: 1,
    };
  },
  methods: {
    showLogout() {
      console.log(11111111);
      this.showLogoutDialog = true;
    },
    logout() {
      this.showLogoutDialog = false;
    },
    async FetchUserMsgTotal() {
      const res = await getUserMsgTotal(localStorage.getItem('id'));
      if (res.status == 0) {
        this.leaveMsg = res.data.leaveMsg;
        this.personMsg = res.data.personMsg;
        this.internalAdvertiseMsg = res.data.internalAdvertiseMsg;
        this.externalAdvertiseMsg = res.data.externalAdvertiseMsg;
        this.zgydAdvertiseMsg = res.data.zgydAdvertiseMsg;
        this.oaMsg = res.data.oaMsg;
        this.probationeMsg = res.data.probationeMsg;
        this.cultivateMsg = res.data.cultivateMsg;
        this.performanceMsg = res.data.performanceMsg;
      } else {
        this.$message.error(res.message);
      }
    },
  },
  mounted() {
    // this.FetchUserMsgTotal()
  },
};
</script>
<style lang="scss" scoped>
@mixin global_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// 顶部logo
.navbar {
  width: 100%;
  height: 60px;
  position: fixed;
  background: #fff;
  z-index: 100;
  box-sizing: border-box;
  @include global_flex;
  .nav-left {
    width: 30%;
    height: 60px;

    .img-logo {
      display: inline-block;
      height: 50px;
      width: 100px;
      padding: 5px 5px;
      border-right: 3px solid #cbe6f1;
    }
    .logo-text {
      height: 60px;
      width: 100%;
      line-height: 60px;
      font-size: 20px;
      color: #10759f;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .nav-right {
    width: 200px;
    height: 60px;

    .username {
      display: inline-block;
      min-width: 100px;
      color: #10759f;
    }
    .logout {
      color: #10759f;
      cursor: pointer;
      line-height: 60px;
    }
  }
}
// 导航条
.navTab {
  width: 100%;
  height: 50px;
  background: #01a7f1;
  position: fixed;
  color: #fff;
  font-size: 16px;
  line-height: 50px;
  z-index: 100;
  margin-top: 60px;
  li {
    width: auto;
    height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
