import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '../store';
// import {
//   getToken
// } from '@/utils/auth'

axios.defaults.withCredentials = true;
// 创建axios实例
let url = process.env.VUE_APP_API_BASEURL;
const service = axios.create({
  baseURL: url, // , // api的base_url
  timeout: 20000, // 请求超时时间
});
axios.defaults.withCredentials = true;
// request拦截器
// var returnResponse = false

service.interceptors.request.use(
  config => {
    config.baseURL = config.burl || config.baseURL;
    // if (config.str) config.data = Qs.stringify(config.data);
    // returnResponse = config.url.indexOf('buyerVerify/exportRAR') !== -1
    if (store.getters.token) {
      config.headers['Token'] = localStorage.getItem('token'); // 让每个请求携带自定义token 请根据实际情况自行修改
      //  config.headers['Token'] = 'eyJhbGciOiJIUzUxMiJ9.eyJuYW1lIjoiY2FpeWFuZ2h1aTFAYmFzZXN0b25lZGF0YS5jb20iLCJzdWIiOiJ2YWluIiwiaWQiOjgxLCJ0eXBlIjozLCJleHAiOjE1NjYyMTAyMjYsImlhdCI6MTU2NTYwNTQyNn0.RSCUXTPQzirO_ykHJC57a9jNmWowh6ffef5IJzkTtl45dOR8-Rx-3DpQ4sqTi8fLCDogQRPlCCG289glWMUIKA'
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  },
);

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    closeLoading();

    const res = response.data;
    // console.log(response)
    // 供应商部分的接口返回的格式不太一样，做一下处理
    if (!res.code && res.resultCode) {
      res.code = parseInt(res.resultCode);
      res.message = res.resultMessage;
    }
    if (res.code && typeof res.code === 'string') {
      res.code = JSON.parse(res.code);
    }
    if (res.code && res.code !== 200) {
      if (response.config.showError) return response.data;
      if (res.code === 404) {
        Message({
          message: '请求的数据不存在',
          type: 'error',
          duration: 5 * 1000,
        });
        return res;
      }
      // 401:token失效; 402:不合法token;  1104:Token ;
      if (res.code === 401 || res.code === 402 || res.code === 1104) {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
        return res;
      }
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(res.message || 'error');
    } else {
      return response.data;
    }
  },
  error => {
    closeLoading();
    console.log('err' + error); // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  },
);

/**
 * 关闭loading效果
 * element-ui 目前没有全局关闭的方式，在使用async函数的时候，在promise为 reject状态下 关闭loading效果
 */
function closeLoading() {
  window.$emuiLoading && window.$emuiLoading.close();
}

export default service;
